import React, {Component} from 'react'

export class ClickTop extends Component {
    render() {
        return (
            <a id="totop" href="#top">
                <i className="fa fa-angle-up"/>
            </a>
        );
    }
}

export default ClickTop;
