import React, {Component} from 'react'
import {Link} from "react-router-dom";
import CommonComponent from "../common/CommonComponent";

export class WhoWeAre extends CommonComponent {
    constructor(props) {
        super(props);
        this.state= {
            data: this.props.data
        }
    }

    render() {
        const data = this.state.data.whoWeAre;
        return (
            <section
                className="ttm-row home3-services-section bg-layer bg-layer-equal-height break-1199-colum mt_207 res-991-mt-0 clearfix">
                <div className="container">
                    {/* row   */}
                    <div className="row">
                        <div className="col-lg-7 col-md-12">
                            {/* spacing-4   */}
                            <div className="ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-skincolor spacing-6">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"/>
                                <div className="layer-content">
                                    <div>
                                        {/* section title   */}
                                        <div className="section-title clearfix">
                                            <div className="title-header">
                                                <h5>{data?.subtitle}</h5>
                                                <h2 className="title">{data?.title}</h2>
                                            </div>
                                            <div className="title-desc">{data?.desc}</div>
                                        </div>
                                        {/* section title end   */}
                                        {data && data.content && data.content.length && data.content.map((item, i) => (
                                            <React.Fragment key={i}>
                                                <div
                                                    className="featured-icon-box featuredbox-number style3 left-icon icon-align-top">
                                                    <div className="featured-icon">
                                                        <div
                                                            className="ttm-icon ttm-icon_element-color-black ttm-icon_element-fill ttm-icon_element-background-white ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                                                            <i className="ti ti-info ttm-num"/>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h5>{item.name}</h5>
                                                            <p>{item.value}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="separator">
                                                    <div className="sep-line mt_5 mb-35"/>
                                                </div>
                                            </React.Fragment>
                                        ))}

                                        {/*<a href="about"
                                              className="ttm-btn btn-inline ttm-btn-color-white ttm-btn-size-lg mb-20 text-left ttm-normal-btn-font ttm-icon-btn-right"
                                        >Do You Need Our Help
                                            <i className="ti ti-angle-right"/>
                                        </Link>*/}
                                    </div>
                                </div>
                            </div>
                            {/* spacing-4 end  */}
                        </div>
                        <div className="col-lg-5 col-md-12">
                            {/* col-img-img-nine   */}
                            <div
                                className="col-bg-img-nine ttm-col-bgimage-yes ttm-bg ml_70 res-991-ml-0 ttm-right-span whoWeAreIMG">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" style={{backgroundImage: `url('${this.imgUrl}${data?.img}')`}}>
                                </div>
                                <div className="layer-content">
                                </div>
                            </div>
                            {/* col-img-bg-img-nine end  */}
                            {/*<img src={data?.img}
                                 className="ttm-equal-height-image" alt={data?.subtitle}/>*/}
                        </div>
                    </div>
                    {/* row end   */}
                </div>
            </section>
        );
    }
}

export default WhoWeAre;
