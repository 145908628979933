import React, {Component} from 'react'
import {Link} from "react-router-dom";

export class TagsSidebar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {data} = this.props;
        return (
            <div className="widget tagcloud-widget">
                <h3 className="widget-title">{data?.title}</h3>
                <div className="tagcloud">
                    {data?.content?.length && data.content.map((item, i) => (
                        <a key={i} href={'/posts?tag=' + item} className="tag-cloud-link">{item}</a>
                    ))}
                </div>
            </div>
        );
    }
}

export default TagsSidebar;
