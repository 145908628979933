import React, {Component} from 'react'
import SubpageTitle from "../widgets/SubpageTitle";
import PlansProfile from "./PlansProfile";
import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import RecentPosts from "../widgets/RecentPosts";
import GetinTouch from "../widgets/GetinTouch";
import SidebarBanner from "../widgets/SidebarBanner";
import TagsSidebar from "../widgets/TagsSidebar";
import PlansList from "../widgets/PlansList";
import PageNotFound from "./PageNotFound";

export class PlansPage extends Component {
    path;

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data,
            path: this.props.location.pathname
        };
        this.refreshPage = this.refreshPage.bind(this);

        if (this.isBrowser) {
            this.init();
        }
    }

    init() {
        this.loadData(`/pages${this.props.location.pathname}`).then((res) => {
            if (res.length) {
                const {data} = this.state;
                this.setPages(data, res[0]);
                this.setState({data});
            }
        });
    }


    refreshPage(event, path) {
        event.preventDefault();
        console.log(event, path);
        const currentPath = this.state.path;
        if (currentPath != path) {
            this.setState({path});
        }
    }

    render() {
        const {location} = this.props;
        const {data} = this.state;
        const subRoutes = data && data.menu && data.menu.filter(a => /^plans.*$/.test(a.path.replace(/^\/|\/$/g, '')))[0].children;
        const page = data && data.pages && data.pages.filter(a => a.path === location.pathname || /^plans.*$/.test(a.path.replace(/^\/|\/$/g, '')))[0];
        const images = data && data.images;
        const plansList = data && data.widgets && data.widgets.filter(a => a.name === 'plansList')[0];
        const tags = data && data.widgets && data.widgets.filter(a => a.name === 'appTags')[0];
        const banner = data && data.widgets && data.widgets.filter(a => a.name === 'banner')[0];
        if(this.isBrowser) {
            this.props.setTitle(page?.meta?.title);
        }
        return (
            <React.Fragment>
                {page ? (<React.Fragment>
                    <SubpageTitle data={this.props.data} location={this.props.location}/>
                    <div className="site-main">

                        <div className="sidebar ttm-sidebar-left ttm-bgcolor-white clearfix">
                            <div className="container">
                                <div className="row d-block">
                                    <div className="col-lg-9 content-area">
                                        <Router>
                                            <Switch>
                                                {
                                                    subRoutes.map((item, i) => (
                                                        <Route key={i} exact path={item.path}
                                                               component={(props) => <PlansProfile
                                                                   data={this.state.data}
                                                                   setTitle={this.props.setTitle}
                                                                   refreshPage={this.refreshPage}
                                                                   path={this.state.path}{...props} />}/>
                                                    ))
                                                }
                                            </Switch>
                                        </Router>
                                       {/* {plansList ? (<PlansList data={{plansList, images}}/>) : ''}*/}
                                        {/*<PlansProfile/>*/}
                                    </div>
                                    <div
                                        className="col-lg-3 widget-area sidebar-left ttm-col-bgcolor-yes ttm-bg ttm-left-span ttm-bgcolor-grey">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{backgroundImage: `url('${this.imgUrl}${ images?.pageNotFound}')`}}  />
                                        <aside className="widget widget-nav-menu">
                                            <ul className="widget-menu">
                                                {subRoutes?.length && subRoutes.map((item, i) => (
                                                    <React.Fragment key={i}>
                                                        {item.path ? (
                                                            <li onClick={(event) => this.refreshPage(event, (item.path))}
                                                                className={item.path === this.state.path ? 'active' : ''}>
                                                                <a
                                                                    href={item.path}>{item.desc}</a>
                                                            </li>) : ''}
                                                    </React.Fragment>
                                                ))}
                                            </ul>
                                        </aside>
                                        <aside className="widget post-widget">
                                            <RecentPosts data={data}/>
                                        </aside>
                                        <aside>
                                            <GetinTouch data={data}/>
                                        </aside>
                                        <aside>
                                            <SidebarBanner data={banner}/>
                                        </aside>
                                        <aside>
                                            <TagsSidebar data={tags}/>
                                        </aside>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </React.Fragment>) : <PageNotFound data={data}/>}

            </React.Fragment>
        );
    }
}

export default PlansPage;
