import React, {Component} from 'react'
import HowItWorks from "../widgets/HowItWorks";
import ClientBrands from "../widgets/ClientBrands";
import {HealthyBanner} from "../widgets/HealthyBanner";
import BlogPosts from "../widgets/BlogPosts";
import WhoWeAre from "../widgets/WhoWeAre";
import {MSG} from "../../shared/Messages";
import {Link} from "react-router-dom";
import FoundedIn from "../widgets/FoundedIn";
import HomeBanner from "../widgets/HomeBanner";
import PlansList from "../widgets/PlansList";
import CommonComponent from "../common/CommonComponent";

class HomePage extends CommonComponent {
    render() {
        const {data, location} = this.props;
        const page = data && data.pages && data.pages.filter(a => a.path === location.pathname)[0];
        const images = data && data.images;
        const howItWorks = data && data.widgets && data.widgets.filter(a => a.name === 'howItWorks')[0];
        const clientBrands = data && data.widgets && data.widgets.filter(a => a.name === 'clients')[0];
        const healthyBanner = data && data.widgets && data.widgets.filter(a => a.name === 'healthyBanner')[0];
        const whoWeAre = data && data.widgets && data.widgets.filter(a => a.name === 'whoWeAre')[0];
        const foundedIn = data && data.widgets && data.widgets.filter(a => a.name === 'foundedIn')[0];
        const homeBanner = data && data.widgets && data.widgets.filter(a => a.name === 'homeBanner')[0];
        const plansList = data && data.widgets && data.widgets.filter(a => a.name === 'plansList')[0];
        if(this.isBrowser) {
            this.props.setTitle(page?.meta?.title);
        }
        return (
            <div>
                {homeBanner ? (<HomeBanner data={homeBanner}/>) : ''}
                <div className="site-main">
                    {/*home3-about-section  */}
                    <section className="ttm-row home3-about-section clearfix">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    {/* ttm_single_image-wrapper   */}
                                    <div className="ttm_single_image-wrapper res-991-mt-60">
                                        <img className="img-fluid" src={`${this.imgUrl}${page?.img}`}
                                             alt={page?.subtitle}/>
                                    </div>
                                    {/* ttm_single_image-wrapper end   */}
                                    {/* ttm-fid   */}
                                    {foundedIn ? (<FoundedIn data={foundedIn}/>) : ''}
                                    {/* ttm-fid end  */}
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="pl-37 res-991-pl-0">
                                        <div className="section-title mb-35 clearfix">
                                            {/* section title   */}
                                            <div className="title-header">
                                                <h5>{page?.subtitle}</h5>
                                                <h1 className="title">{page?.title}</h1>
                                            </div>
                                            <div className="text-justify" dangerouslySetInnerHTML={MSG.markup(page?.content)}/>
                                        </div>

                                       {/* <div className="row">
                                            <div className="col-md-12">
                                                <a href="/about"
                                                      className="ttm-btn ttm-btn-size-md ttm-btn-shape-round ttm-btn-style-border ttm-btn-color-black mb-20"
                                                >Read More</a>
                                                <a href="/contact"
                                                      className="ttm-btn ttm-btn-size-md ttm-btn-shape-round ttm-btn-style-fill ttm-btn-bgcolor-skincolor ml-15 mb-20"
                                                >Contact Us</a>
                                            </div>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                            {/* row end   */}
                        </div>
                    </section>
                    {/*home3-about-section end  */}


                    {/* working-section   */}
                    <section className="ttm-row home3-working-section bg-img3 clearfix">
                        <div className="container">
                            {/* row   */}
                            <div className="row">
                                <div className="col-md-12 mt_120 res-991-mt_50">
                                    {/* row   */}
                                    <div className="row">
                                        <div className="blog-slide owl-carousel owl-theme owl-loaded" data-item="3"
                                             data-nav="false" data-dots="false" data-auto="true">

                                        </div>
                                    </div>
                                    {/* row end   */}
                                </div>
                            </div>
                            {/* row end   */}
                        </div>
                    </section>
                    {/* working-section end   */}

                    {/*home3-services-section  */}
                    {whoWeAre ? (<WhoWeAre data={{whoWeAre, images}}/>) : ''}
                    {/*home3-services-section end  */}


                    {/*pricing-plan-section  */}
                    {/*{plansList ? (<PlansList data={{plansList,images}}/>) : ''}*/}

                    {/*pricing-plan-section end  */}


                    {/* post-section   */}
                    {data ? <BlogPosts data={data}/>: ''}
                    {/* post-section end   */}

                    {howItWorks ? (<HowItWorks data={{howItWorks,images}}/>) : ''}

                    {/*client-section  */}
                    {clientBrands ? (<ClientBrands data={{clientBrands,images}}/>) : ''}

                    {/*client-section end  */}

                    {/*home3-row-title-section  */}
                    {healthyBanner ? (<HealthyBanner data={{healthyBanner,images}}/>) : ''}
                    {/*home3-row-title-section end  */}

                </div>
            </div>
        );
    }
}

export default HomePage;
