import React from 'react'
import {BrowserRouter, Route, StaticRouter, Switch} from "react-router-dom";
import axios from 'axios'
import './App.css';

import {routes} from './routes'
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import ClickTop from "./components/common/ClickTop";
import {CommonComponent} from "./components/common/CommonComponent";
import ScrollIntoView from "./components/common/ScrollIntoView";

let Router;
if (typeof document !== "undefined") {
    Router = BrowserRouter;
} else {
    Router = StaticRouter;
}

export class App extends CommonComponent {

    constructor(props) {
        super(props);

        let data = null;
        if (this.isBrowser) {
            data = window.WINDOW_STATE;
            if(!data) {
                this.init();
            }
        } else {
            data = null;
            console.log('Need to handle data store')
        }
        this.state = {data};
    }

    setTitle(title) {
        document.title = title ? `${title} - nutricare` : 'Welcome to Sr Radha nutricare';
    }

    init() {
            axios.all([
                axios.get(this.apiUrl + '/default'),
            ])
                .then(axios.spread((res) => {
                    if (res && res.data && res.data.status === 200) {
                        const data = res.data.result[0];
                        this.setState({data});
                    } else {
                        // todo: error scenario need to add
                    }


                }));
    }

    render() {
        const path = this.isBrowser ? window.location.pathname : '/';
        return (<Router>
                <div className="page">

                    <ScrollIntoView>
                        {this.state.data ?
                            <React.Fragment>
                                {/*header start  */}
                                <Header data={this.state.data}/>
                                {/*header end  */}
                                <Switch>
                                    {
                                        routes.map((item, i) => (
                                            <Route key={path} exact path={item.path}
                                                   component={(props) => <item.component setTitle={this.setTitle}
                                                                                         key={path}
                                                                                         data={this.state.data} {...props} />}/>
                                        ))
                                    }
                                </Switch>
                                {/*site-main end  */}
                                {/*footer start  */}
                                <Footer data={this.state.data}/>
                                {/* {/*footer end  *{/*/}

                                {/*{/*back-to-top start  *{/*/}
                                <ClickTop/>
                                {/*{/*back-to-top end  *{/*/}
                            </React.Fragment>
                            :
                            <React.Fragment>

                                {/*  preloader start */}
                                <div id="preloader">
                                    <div
                                        style={{backgroundImage: `url('${this.imgUrl}${this.state.data?.images?.preLoader}')`}}
                                        id="status">&nbsp;</div>
                                </div>
                                {/* preloader end  */}
                            </React.Fragment>
                        }
                    </ScrollIntoView>
                </div>
            </Router>
        );
    }
}

export default App;
;