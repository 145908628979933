import React from 'react'
import SubpageTitle from "../widgets/SubpageTitle";
import HowItWorks from "../widgets/HowItWorks";
import {MSG} from "../../shared/Messages";
import ClientBrands from "../widgets/ClientBrands";
import {HealthyBanner} from "../widgets/HealthyBanner";
import PageNotFound from "./PageNotFound";
import CommonComponent from "../common/CommonComponent";

export class AboutUs extends CommonComponent {
    constructor(props) {
        super(props);
        const {data, location} = this.props;
        this.state = {data};
        if (this.isBrowser) {
            const stateData = window.WINDOW_STATE;
            if (!stateData) {
                this.init();
            }
        }
    }

    init() {
        this.loadData(`/pages${this.props.location.pathname}`).then((res) => {
            if (res.length) {
                const {data} = this.state;
                this.setPages(data, res[0]);
                this.setState({data});
            }
        });
    }

    render() {
        const {location} = this.props;
        const {data} = this.state;
        const page = data && data.pages && data.pages.filter(a => a.path === location.pathname)[0];
        const images = data && data.images;
        const howItWorks = data && data.widgets && data.widgets.filter(a => a.name === 'howItWorks')[0];
        const clientBrands = data && data.widgets && data.widgets.filter(a => a.name === 'clients')[0];
        const healthyBanner = data && data.widgets && data.widgets.filter(a => a.name === 'healthyBanner')[0];
        if (this.isBrowser) {
            this.props.setTitle(page?.meta?.title);
        }
        return (
            <React.Fragment>
                {page ? (<React.Fragment>
                    <SubpageTitle data={this.props.data} location={this.props.location}/>
                    <div className="site-main">
                        <section className="ttm-row about-top-section clearfix">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-5 col-md-12 col-sm-12">
                                        <div className="ttm_single_image-wrapper res-991-mt-60 mb-35">
                                            <div className="text-left">
                                                <img className="img-fluid"
                                                     src={`${this.imgUrl}${page?.img}`} alt={page.title}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-12 col-sm-12">
                                        <div className="ml_50 res-1199-ml-0">
                                            <div className="section-title clearfix">
                                                <div className="title-header">
                                                    <h5>{page?.subtitle}</h5>
                                                    <h1 className="title"
                                                        dangerouslySetInnerHTML={MSG.markup(page?.title)}/>
                                                </div>
                                                <div className="title-desc text-justify"
                                                     dangerouslySetInnerHTML={MSG.markup(page?.content)}/>
                                            </div>
                                            {data.properties.badge ? <span><img src={data.properties.badge}
                                                                                alt="ttm_single_image-wrapper"/></span> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {howItWorks ? (<HowItWorks data={{howItWorks, images}}/>) : ''}

                        {/*client-section  */}
                        {clientBrands ? (<ClientBrands data={{clientBrands, images}}/>) : ''}

                        {/*client-section end  */}

                        {/*home3-row-title-section  */}
                        {healthyBanner ? (<HealthyBanner data={{healthyBanner, images}}/>) : ''}
                        {/*home3-row-title-section end  */}

                    </div>
                </React.Fragment>) : <PageNotFound data={data}/>}

            </React.Fragment>
        );
    }
}

export default AboutUs;
