const configData = [
    {
        name: 'name',
        type: 'app',
        validations: {
            required: true,
            minLength: 5,
            maxLength: 20,
            pattern: /^[a-zA-Z .]+$/
        }
    },
    {
        name: 'email',
        type: 'app',
        validations: {
            required: true,
            minLength: 8,
            maxLength: 50,
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        }
    },
    {
        name: 'phone',
        type: 'app',
        validations: {
            required: true,
            minLength: 10,
            maxLength: 14,
            pattern: /(7|8|9)\d{9,13}/
        }
    },
    {
        name: 'comment',
        type: 'app',
        validations: {
            required: true,
            minLength: 8,
            maxLength: 1000,
            pattern: /^[\s\S]+$/
        }
    },
    {
        name: 'subject',
        type: 'app',
        validations: {
            required: true,
            minLength: 5,
            maxLength: 20,
            pattern: /^[a-zA-Z0-9 .]+$/
        }
    },
];

export const ValidationsHelper = {

    getErrors: (value, field, errors, type) => {
        ValidationsHelper.validate(field, type, value, errors);
        return errors;
    },
    isListValid: (data, errors, type) => {
        let formIsValid = true;
        const fields = Object.keys(data);
        fields.map(field => {
            errors = ValidationsHelper.getErrors(data[field], field, errors, field === 'meta' ? 'meta' : type)
        });
        const keys = Object.keys(errors);
        for (var i = 0; i < keys.length; i++) {
            const k = keys[i];
            if (k === 'meta') {
                if (errors.meta.title || errors.meta.keywords || errors.meta.description) {
                    formIsValid = false;
                    break;
                }
            } else if (errors[k]) {
                formIsValid = false;
                break;
            }
        }
        return formIsValid;
    },
    validate: (name, type, value, errors) => {
        switch (type) {
            case 'app':
                ValidationsHelper.textValidate(name, type, value, errors);
                break;
        }
    },
    metaValidate: (name, type, value, errors) => {
        const ind = configData.findIndex(a => a.name === name && a.type === type);
        errors[type][name] = null;
        if (ind >= 0) {
            const config = configData[ind];
            const keys = Object.keys(config.validations);
            for (var i = 0; i < keys.length; i++) {
                const k = keys[i];
                switch (k) {
                    case 'required':
                        ValidationsHelper.requiredCheckGroup(config.validations[k], errors, name, value, type);
                        break;
                    case 'minLength':
                        ValidationsHelper.minLengthCheckGroup(config.validations[k], errors, name, value, type);
                        break;
                    case 'maxLength':
                        ValidationsHelper.maxLengthCheckGroup(config.validations[k], errors, name, value, type);
                        break;
                }
                if (errors[type][name]) {
                    break;
                }
            }
        }

    },
    textValidate: (name, type, value, errors) => {
        const ind = configData.findIndex(a => a.name === name && a.type === type);

        errors[name] = null;
        if (ind >= 0) {
            const config = configData[ind];
            const keys = Object.keys(config.validations);
            for (var i = 0; i < keys.length; i++) {
                const k = keys[i];
                switch (k) {
                    case 'required':
                        ValidationsHelper.requiredCheck(config.validations[k], errors, name, value);
                        break;
                    case 'minLength':
                        ValidationsHelper.minLengthCheck(config.validations[k], errors, name, value);
                        break;
                    case 'maxLength':
                        ValidationsHelper.maxLengthCheck(config.validations[k], errors, name, value);
                        break;
                    case 'pattern':
                        ValidationsHelper.patternCheck(config.validations[k], errors, name, value);
                        break;
                    case 'filerequired':
                        ValidationsHelper.fileCheck(config.validations[k], errors, name, value);
                        break;
                }
                if (errors[name]) {
                    break;
                }
            }
        }
    },
    requiredCheckGroup: (config, errors, field, value, group) => {
        errors[group][field] = value ? null : `${field.toUpperCase()} is required field`
    },
    minLengthCheckGroup: (config, errors, field, value, group) => {
        errors[group][field] = value && value.length > config ? null : `Min ${config} characters are required`;
    },
    maxLengthCheckGroup: (config, errors, field, value, group) => {
        errors[group][field] = value && value.length < config ? null : `Max length should be ${config} characters`
    },
    requiredCheck: (config, errors, field, value) => {
        errors[field] = value ? null : `${field.toUpperCase()} is required field`
    },
    minLengthCheck: (config, errors, field, value) => {
        errors[field] = value && value.length >= config ? null : `Min ${config} characters are required`;
    },
    maxLengthCheck: (config, errors, field, value) => {
        errors[field] = value && value.length <= config ? null : `Max length should be ${config} characters`
    },
    patternCheck: (config, errors, field, value) => {
        errors[field] = config.test(value) ? null : `Invalid ${field.toUpperCase()} entered, ${field.toUpperCase()} should contain alpha numeric values`
    },
    fileCheck: (config, errors, field, value) => {
        errors[field] = value?.name ? null : null;
    }
};
