import React, {Component} from 'react'
import SubpageTitle from "../widgets/SubpageTitle";
import ContactForm from "../widgets/ContactForm";
import {Link} from "react-router-dom";
import {MSG} from "../../shared/Messages";
import PageNotFound from "./PageNotFound";
import CommonComponent from "../common/CommonComponent";

export class ContactUs extends CommonComponent {
    constructor(props) {
        super(props);
        const {data, location} = this.props;
        this.state = {data};
        if (this.isBrowser) {
            const stateData = window.WINDOW_STATE;
            if (!stateData) {
                this.init();
            }
        }
    }

    init() {
        this.loadData(`/pages${this.props.location.pathname}`).then((res) => {
            if (res.length) {
                const {data} = this.state;
                this.setPages(data, res[0]);
                this.setState({data});
            }
        });
    }


    render() {
        const {location} = this.props;
        const {data} = this.state;
        const page = data && data.pages && data.pages.filter(a => a.path === location.pathname)[0];
        if(this.isBrowser) {
            this.props.setTitle(page?.meta?.title);
        }
        return (
            <React.Fragment>
                {page ? (<React.Fragment>
                    <SubpageTitle data={this.props.data} location={this.props.location}/>
                    <section className="ttm-row contact-top-section ttm-bgcolor-grey clearfix">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div
                                                className="featured-icon-box without-icon ttm-bgcolor-skincolor spacing-11">
                                                <div className="featured-title">
                                                    <h5 className="mb-15">Clinic</h5>
                                                </div>
                                                <div className="featured-desc"
                                                     dangerouslySetInnerHTML={MSG.markup(data.properties?.location)}/>
                                                <ul className="ttm-list ttm-list-style-icon ttm-textcolor-white">
                                                    <li><a href={'mailto:' + data?.properties?.email} target="_blank"><i
                                                        className="fa fa-envelope-o"/>{data?.properties?.email}</a></li>
                                                    <li><i className="fa fa-phone"/>{data?.properties?.phone}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div
                                                className="featured-icon-box without-icon ttm-bgcolor-skincolor spacing-11">
                                                <div className="featured-title">
                                                    <h1 className="mb-15">Quick Contact for Online and Clinic Consultation</h1>
                                                </div>
                                                <ul className="ttm-list ttm-list-style-icon ttm-textcolor-white">
                                                    <li><a href={'mailto:' + data?.properties?.consultEmail} target="_blank"><i
                                                        className="fa fa-envelope-o"/>{data?.properties?.consultEmail}</a></li>
                                                    <li><i className="fa fa-phone"/>{data?.properties?.consultPhone}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row pt-20 pr-20 pl-20 pb-20 box-shadow1 ttm-bgcolor-white mb-25">
                                        <div className="col-md-12">
                                            <h4>Send Your Message</h4>
                                            <p>Dont hesitate to send message us, Our team will help you 24/7.</p>
                                            {this.isBrowser ? <ContactForm/> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>) : <PageNotFound data={data}/>}
            </React.Fragment>
        );
    }
}

export default ContactUs;
