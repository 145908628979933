import React from 'react'
import {Link} from "react-router-dom";
import SocialLinks from "./SocialLinks";
import CommonComponent from "./CommonComponent";

export class Header extends CommonComponent {
    constructor(props) {
        super(props);
        this.state = {
            toggleMenu: false,
            ismobile: this.isBrowser ? window && window.screen.width <= 1199 : false
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeToggle = this.closeToggle.bind(this);
    }

    toggleMenu(e) {
        // e.preventDefault();
        if (this.isBrowser) {
            if (window && window.screen.width <= 1199) {
                const toggleMenu = !this.state.toggleMenu;
                const ismobile = true;
                this.setState({toggleMenu, ismobile});
            }
        }
    }

    closeToggle() {
        if (this.state.ismobile) {
            const toggleMenu = false;
            this.setState({toggleMenu});
        }
    }


    render() {
        const {data} = this.props;
        const {menu} = data;

        return (
            <header id="masthead" className="header ttm-header-style-classic">
                {/* ttm-topbar-wrapper   */}
                <div className="ttm-topbar-wrapper ttm-bgcolor-black ttm-textcolor-white clearfix">
                    <div className="container">
                        <div className="ttm-topbar-content">
                            <ul className="list-inline top-contact  topbar-left text-left">
                                <li><i className="fa fa-map-marker"> </i> {data?.properties?.shortLoc}</li>
                                <li><i className="fa fa-clock-o"/> {data?.properties?.openHoursShort}</li>
                            </ul>
                            <div className="topbar-right text-right">
                                <ul className="list-inline top-contact">
                                    <li><a href={'mailto:' + data?.properties?.email} target="_blank"><i
                                        className="fa fa-envelope-o"/>{data?.properties?.email}</a></li>
                                </ul>
                                <div className="ttm-social-links-wrapper list-inline">
                                    <SocialLinks data={this.props.data}/>
                                </div>
                                {/*<div className="ttm-topbar-btn ttm-textcolor-white">
                                    <a href="/schedule-appointment"
                                          className="ttm-btn ttm-btn-size-md ttm-btn-bgcolor-skincolor">
                                        Book Appointment
                                    </Link>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                {/* ttm-topbar-wrapper end   */}
                {/* ttm-header-wrap   */}
                <div className="ttm-header-wrap">
                    {/* ttm-stickable-header-w   */}
                    <div id="ttm-stickable-header-w" className="ttm-stickable-header-w clearfix">
                        <div id="site-header-menu" className="site-header-menu">
                            <div className="site-header-menu-inner ttm-stickable-header">
                                <div className="container">
                                    { /* site-branding   */}
                                    <div className="site-branding">
                                        <a href={data?.properties?.url || '/'} className="home-link"
                                              title={data.properties?.name} rel="home">
                                            <img id="logo-img" className="img-center"
                                                 src={`${this.imgUrl}${data?.properties?.logo}`}
                                                 alt={data.properties?.name}/>
                                        </a>
                                    </div>
                                    {/* site-branding end   */}
                                    {/*site-navigation   */}
                                    <div id="site-navigation" className="site-navigation">
                                        {/* header-icins   */}
                                        {/*<div className="ttm-header-icons ">
                                        <span className="ttm-header-icon ttm-header-cart-link">
                                            Login <a href="/login"><i className="fa fa-lock"/>
                                             <span className="number-cart">0</span>
                                            </Link>
                                        </span>
                                        </div>*/}
                                        {/* header-icons end   */}
                                        <div className="ttm-menu-toggle">
                                            <input type="checkbox" id="menu-toggle-form"/>
                                            <label onClick={this.toggleMenu} htmlFor="menu-toggle-form"
                                                   className="ttm-menu-toggle-block">
                                                <span className="toggle-block toggle-blocks-1"/>
                                                <span className="toggle-block toggle-blocks-2"/>
                                                <span className="toggle-block toggle-blocks-3"/>
                                            </label>
                                        </div>
                                        <nav id="menu" className="menu"
                                             style={{display: (this.state.ismobile && !this.state.toggleMenu) ? 'none' : 'block'}}>
                                            {menu && menu.length && (
                                                <ul className="dropdown">
                                                    {/*TODO: Active Class need to add to li className="active"*/}
                                                    {menu.map((item, i) => (
                                                        <li key={i} onClick={this.closeToggle}>
                                                            <a href={item.path}>{item.desc}</a>
                                                            {item.children?.length && (
                                                                <ul>
                                                                    {item.children.map((item1, i) => (
                                                                        <li key={i}>
                                                                            <a href={item1.path}>{item1.desc}</a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </li>))}
                                                </ul>
                                            )}
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ttm-stickable-header-w end  */}
                    </div>
                    {/*ttm-header-wrap end   */}
                </div>
            </header>
        );
    }
}

export default Header;
