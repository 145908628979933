import React, {Component} from 'react'
import {Link} from "react-router-dom";
import CommonComponent from "../common/CommonComponent";

export class SidebarBanner extends CommonComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {data} = this.props;
        return (
            <div className="widget widget_media_image">
                <a href={data?.desc || '/'}><img className="img-fluid"
                                                         src={`${this.imgUrl}${data?.img}`}
                                                         alt={data?.title}/></a>
            </div>
        );
    }
}

export default SidebarBanner;
