import React, {Component} from 'react'
import {Link} from "react-router-dom";

export class PlansList extends Component {
    constructor(props) {
        super(props);
        this.state= {
            data: this.props.data
        }
    }

    render() {
        const data = this.state.data.plansList;
        return (
            <section className="ttm-row pricing-plan-section ttm-bgcolor-grey clearfix">
                <div className="container">
                    {/* row   */}
                    <div className="row">
                        <div className="col-md-12">
                            {/* section title   */}
                            <div className="section-title text-center clearfix">
                                <div className="title-header">
                                    <h5>{data.subtitle}</h5>
                                    <h2 className="title">{data?.title}</h2>
                                </div>
                                <div className="title-desc">
                                    <p>{data?.desc}</p>
                                </div>
                            </div>
                            {/* section title end   */}
                        </div>
                    </div>
                    {/* row end   */}
                    <div className="row text-center m-0 res-1199-mlr-15">
                        {data.content?.map((item,i) => (
                            <div key={i} className="col-md-4 p-0">
                                <div className={'ttm-pricing-plan clearfix ' + (item.featured ? 'ttm-ptablebox-featured-col' : '')}>
                                    <div className="ttm-ptablebox-title"><h3>{item.title}</h3></div>
                                    <div className="ttm-ptablebox-price-w">
                                        <div className="ttm-ptablebox-price">{item.price}</div>
                                        <div className="ttm-ptablebox-cur-symbol-after"/>
                                        <div className="ttm-ptablebox-frequency">{item.priceDuration}</div>
                                        <div className="ttm-ptablebox-desc">{item.description}</div>
                                    </div>
                                    <div className="ttm-ptablebox-content">
                                        <div className="ttm-ptablebox-features">
                                            <ul className="ttm-feature-lines">
                                                {item.features.map((item1,i) => (
                                                    <li key={i}>{item1}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="price_btn">
                                            <a href="/schedule-appointment" className="ttm-btn ttm-btn-size-md ttm-btn-shape-round  ttm-btn-style-fill ttm-btn-bgcolor-skincolor"
                                            >Order Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>
            </section>

        );
    }
}

export default PlansList;
