import React, {Component} from 'react'
import {MSG} from "../../shared/Messages";
import PageNotFound from "./PageNotFound";
import CommonComponent from "../common/CommonComponent";
import TagsSidebar from "../widgets/TagsSidebar";
import SidebarBanner from "../widgets/SidebarBanner";
import GetinTouch from "../widgets/GetinTouch";
import RecentPosts from "../widgets/RecentPosts";
import SubpageTitle from "../widgets/SubpageTitle";

export class PlansProfile extends CommonComponent {

    constructor(props) {
        super(props);
        const {data, location} = this.props;
        this.state = {data};
        if (this.isBrowser) {
            const stateData = window.WINDOW_STATE;
            if (!stateData) {
                this.init();
            }
        }
    }

    init() {
        this.loadData(`/pages/path/${this.props.match.params.id}`).then((res) => {
            if (res.length) {
                const {data} = this.state;
                this.setPages(data, res[0]);
                this.setState({data});
            }
        });
    }

    render() {
        const {data, location, path} = this.props;
        const page = data && data.pages && data.pages.filter(a => a.path === `/${this.props.match.params.id}`)[0];
        const subRoutes = data && data.menu && data.menu.filter(a => /^plans.*$/.test(a.path.replace(/^\/|\/$/g, '')))[0].children;
        const tags = data && data.widgets && data.widgets.filter(a => a.name === 'appTags')[0];
        const banner = data && data.widgets && data.widgets.filter(a => a.name === 'banner')[0];
        const images = data && data.images;
        if(this.isBrowser) {
            this.props.setTitle(page?.meta?.title);
        }
        console.log(page);
        return (
            <React.Fragment>
                {page ? (<React.Fragment>
                    <SubpageTitle data={this.props.data} location={this.props.location}/>
                    <div className="site-main">

                        <div className="sidebar ttm-sidebar-left ttm-bgcolor-white clearfix">
                            <div className="container">
                                <div className="row d-block">
                                    <div className="col-lg-9 content-area">
                    <div className="ttm-service-single-content-area">
                    <div className="ttm_single_image-wrapper mb-25">
                        <img className="img-fluid"
                             src={`${this.imgUrl}${page?.img}`}
                             alt={page.title}/>
                    </div>
                    <div className="ttm-service-description">
                        <h1 className="font-27 line-h35">{page?.title}</h1>
                        {/*<div className="mb-35" dangerouslySetInnerHTML={MSG.markup(page?.subtitle)}>
                        </div>*/}
                    </div>
                    {page?.listType && page.content && page.content.length ? (<React.Fragment>
                        {page?.content.map((item, i) => (
                            <div key={i} className="row">
                                <div className="col-md-6">
                                    <div className="ttm_single_image-wrapper mb-35">
                                        <img className="img-fluid" src={`${this.imgUrl}${item?.img}`} alt={item.title}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ttm-service-description">
                                        <h4>{item.title}</h4>
                                        {/*<div dangerouslySetInnerHTML={MSG.markup(item?.subtitle)}/>*/}
                                    </div>

                                </div>
                            </div>
                        ))}
                    </React.Fragment>) : (
                        <div className="row">
                            <div className="text-justify" dangerouslySetInnerHTML={MSG.markup(page?.content)}/>
                            {/*{page?.content?.length && page?.content.map((item, i) => (
                                <div key={i} className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div
                                                className="featured-icon-box style3 left-icon icon-align-top">
                                                <div className="featured-icon">
                                                    <div
                                                        className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md ttm-icon_element-style-square ttm-icon_element-background-grey">
                                                        <i className={'flaticon flaticon-' + item.icon}/>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h5>{item.title}</h5>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>{item.desc}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}*/}


                        </div>)}


                </div>
                                    </div>
                                    <div
                                        className="col-lg-3 widget-area sidebar-left ttm-col-bgcolor-yes ttm-bg ttm-left-span ttm-bgcolor-grey">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{backgroundImage: `url('${this.imgUrl}${ images?.pageNotFound}')`}}  />
                                        <aside className="widget widget-nav-menu">
                                            <ul className="widget-menu">
                                                {subRoutes?.length && subRoutes.map((item, i) => (
                                                    <React.Fragment key={i}>
                                                    {item.path ? (
                                                        <li onClick={(event) => this.refreshPage(event, (item.path))}
                                                            className={item.path === location.pathname ? 'active' : ''}>

                                                            <a
                                                                href={item.path}>{item.desc}</a>
                                                        </li>) : ''}
                                                    </React.Fragment>
                                                    ))}
                                            </ul>
                                        </aside>
                                        <aside className="widget post-widget">
                                            <RecentPosts data={data}/>
                                        </aside>
                                        <aside>
                                            <GetinTouch data={data}/>
                                        </aside>
                                        <aside>
                                            <SidebarBanner data={banner}/>
                                        </aside>
                                        <aside>
                                            <TagsSidebar data={tags}/>
                                        </aside>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>) : <PageNotFound data={data}/>}

            </React.Fragment>
        );
    }
}

export default PlansProfile;
