export const MSG = {
    test: 'test1',
    markup: (str) => {
        return {
            __html: str
        }
    },
    FOOTER_PLACEHOLDER: 'Backed by 25 years of expertise, let Dr. Radha’s Nutricare guide you on a journey of self-discovery, to better health and wellness.'
};

