import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {MSG} from "../../shared/Messages";

export class GetinTouch extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {data} = this.props;
        return (
            <div className="widget contact-widget">
                <h3 className="widget-title">Get in touch</h3>
                <ul className="contact-widget-wrapper">
                    <li><i className="fa fa-map-marker"/>
                        <span dangerouslySetInnerHTML={MSG.markup(data?.properties?.location)}/>
                    </li>
                    <li><a href={'mailto:' + data?.properties?.email} target="_blank"><i
                        className="fa fa-envelope-o"/>{data?.properties?.email}</a></li>
                    <li><i className="fa fa-phone"/>{data?.properties?.phone}</li>
                    <li><i className="ti ti-world"/><a href={data?.properties?.domain || '/'}
                                                          target="_blank">{data?.properties?.domain}</a>
                    </li>
                    <li><i className="ti ti-alarm-clock"/>{data?.properties.openHours}</li>
                </ul>
            </div>
        );
    }
}

export default GetinTouch;
