import React, {Component} from 'react'
import {Link} from "react-router-dom";

export class QuickLinks extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {footerQuickLinks} = this.props.data;
        return (
            <div className="widget widget_nav_menu clearfix">
                {footerQuickLinks?.length && (<React.Fragment>
                    <h3 className="widget-title">Quick Links</h3>
                    <ul id="menu-footer-services">
                        {footerQuickLinks.map((item, i) => (<li key={i}><a href={item.path}>{item.desc}</a></li>))}
                    </ul>
                </React.Fragment>)}
            </div>
        );
    }
}

export default QuickLinks;
