import React, {Component} from 'react'
import Moment from 'moment';
import {Link} from "react-router-dom";
import {CommonComponent} from "../common/CommonComponent";

export class BlogPosts extends CommonComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {data} = this.props;
        const {posts} = data;
        const blogPosts = data && data.widgets && data.widgets.filter(a => a.name === 'blogPosts')[0];
        const getDate = (date) => {
            return (<React.Fragment> <span
                className="entry-month">{(new Date(date).getDate())}</span>{(new Date(date).getMonth())}
            </React.Fragment>)
        };
        return (

            <section className="ttm-row home3-post-section clearfix">
                <div className="container">
                    {/* row   */}
                    <div className="row">
                        <div className="col-md-12">
                            {/* section title   */}
                            <div className="section-title mb-30 text-center clearfix">
                                <div className="title-header">
                                    <h5>{blogPosts?.subtitle}</h5>
                                    <h2 className="title">{blogPosts?.title}</h2>
                                </div>
                                <div className="title-desc">
                                    <p>{blogPosts?.desc}</p>
                                </div>
                            </div>
                            {/* section title end   */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="post-slide owl-carousel owl-theme owl-loaded" data-item="3"
                             data-nav="false"
                             data-dots="false" data-auto="true">
                            <div className="owl-stage-outer">
                                <div className="owl-stage">
                                    {posts && posts.length &&
                                    posts.slice(0, 5).map((item, i) => (
                                        <div key={i} className="owl-item active">
                                            <div
                                                className="featured-imagebox featured-imagebox-post ttm-box-view-top-image box-shadow1">
                                                <div className="ttm-post-featured-outer">
                                                    <div className="featured-thumbnail">
                                                        <img className="img-fluid" src={`${this.imgUrl}${item?.img}`} alt={item.title}/>
                                                    </div>
                                                    <div className="ttm-box-post-date">
                                                        <span
                                                            className="entry-month">{Moment(item.updated_at).format('D')}</span>
                                                        {Moment(item.updated_at).format('MMM')}


                                                    </div>
                                                </div>
                                                <div className="featured-content featured-content-post">
                                                    <div className="post-title featured-title">
                                                        <h5><a href={`/post/${item.path}`} tabIndex="-1">{item.title}</a></h5>
                                                    </div>
                                                    <div className="post-meta">
                                                        <span className="ttm-meta-line comments-link"><i
                                                            className="fa fa-comment ttm-textcolor-skincolor"/> {item.comments} Comments</span>
                                                        <span className="ttm-meta-line byline"><i
                                                            className="fa fa-user ttm-textcolor-skincolor"/> {item.auth?.name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* row end   */}
                </div>
            </section>
        );
    }
}

export default BlogPosts;
