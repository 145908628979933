import React, {Component} from 'react'
import {Link} from "react-router-dom";

export class SocialLinks extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {social} = this.props.data;
        return (
            <div className="social-icons social-hover">
                <ul className="list-inline">
                    {social && social.length &&
                    social.map((item, i) => (
                        <li key={i} className={'social-' + item.icon}><a href={item.path} data-tooltip={item.desc}
                                                                            className="tooltip-top" target="_blank">
                            <i className={'fa fa-' + item.icon} aria-hidden="true"/></a></li>
                    ))
                    }
                </ul>
            </div>
        );
    }
}

export default SocialLinks;
