import React, {Component} from 'react'

import {MSG} from '../../shared/Messages'
import NewsLetter from "../widgets/NewsLetter";
import RecentPosts from "../widgets/RecentPosts";
import {Link} from "react-router-dom";
import QuickLinks from "../widgets/QuickLinks";
import SocialLinks from "./SocialLinks";
import CommonComponent from "./CommonComponent";

export class Footer extends CommonComponent {
    render() {
        const {data} = this.props;
        const {footerLinks} = data;
        const currentYear = (new Date()).getFullYear();

        return (
            <footer className="footer widget-footer ttm-bgcolor-black clearfix">
                <div className="first-footer">
                    <div className="container">
                        <div className="row align-center">
                            <div className="col-lg-8 col-xs-12 widget-area">
                                <div className="featured-icon-box left-icon">
                                    <div className="featured-icon">
                                        <div
                                            className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                            <i className="fa fa-clock-o"/>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h5>Working Hours:</h5>
                                            <h6>{data?.properties?.openHours}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 <div className="second-footer ttm-textcolor-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                                <div className="widget clearfix">
                                    <div className="footer-logo">
                                        <img id="footer-logo-img" className="img-center" src={`${this.imgUrl}${data?.properties?.logoFooter}`}
                                             alt={data?.name}/>
                                    </div>
                                    <p>{MSG.FOOTER_PLACEHOLDER}</p>
                                    <div className="separator pt-5">
                                        <div className="sep-line mb-30"/>
                                    </div>
                                    <ul className="widget-contact">
                                        <li><a href={'mailto:' + data?.properties?.email} target="_blank"><i
                                            className="fa fa-envelope-o"/>{data?.properties?.email}</a></li>
                                        <li><i className="fa fa-phone"/>{data?.properties?.phone}</li>
                                    </ul>
                                    <SocialLinks data={this.props.data}/>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                                <QuickLinks data={this.props.data}/>
                            </div>
                           <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                                <RecentPosts data={data}/>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                                <NewsLetter/>
                            </div>
                        </div>
                    </div>
                </div>
                 <div className="bottom-footer-text ttm-textcolor-white">
                    <div className="container">
                        <div className="row copyright">
                            <div className="col-md-8 ttm-footer2-left">
                                <span>Copyright © {currentYear} &nbsp;<a href={data?.properties?.url || ''}>{data?.properties?.name}</a>. All rights reserved.</span>
                            </div>
                            <div className="col-md-4 ttm-footer2-right">
                                {footerLinks && footerLinks.length && (
                                    <ul id="menu-footer-menu" className="footer-nav-menu">
                                        {footerLinks.map((item, i) => (
                                            <li key={i}><a href={item.path}>{item.desc}</a></li>))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
