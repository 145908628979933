import React, {Component} from 'react'
import axios from 'axios'
export class CommonComponent extends Component {
    apiUrl = process.env.REACT_APP_API || '/api/public';
    imgUrl = process.env.REACT_APP_HOST || '';
    imgpath = '/images/';
    isBrowser = typeof document != 'undefined';

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            adminData: {},
            pages: [],
            modules: [],
            properties: [],
            widgets: [],
            alertData: null
        };
        this.showAlert = this.showAlert.bind(this);
        this.closeAlert = this.closeAlert.bind(this)
    }


    closeAlert(e) {
        this.prevent(e);
        const alertData = null;
        this.setState({alertData});
    }

    showAlert(msg, type) {
        const alertData = {
            text: msg,
            variant: `dark-${type || 'success' }`
        };
        this.setState({alertData});
    }

    async loadData(url) {
        const response = await axios.get(this.apiUrl + url);
        return response.data.result;
    }

    showSpinner() {
        this.setState({loading: true})
    }
    hideSpinner(duration) {
        setTimeout(() => this.setState({loading: false}), duration || 1000);
    }

    setPages(data, pages) {
        if(data && data.pages && data.pages.length) {
            data.pages = [data.pages, ...[pages]]
        } else {
            data.pages = [pages]
        }
    }

    setProps(field, value){
        this.setState({[field]: value});
        this.props.setData(field, value)
    }

    updateProps(field, value){
        const data =  this.props.data[field];
        if(data) {
            const ind = data.findIndex(a => a._id === value._id);
            data[ind] = value;
            this.props.setData(field, data)
        }
    }

    reloadPropsData(path){
        this.showSpinner();
        this.loadData(`/${path}`).then(path => {
            this.hideSpinner();
            this.setProps(path, path);
        });
    }

    prevent(e) {
        e.preventDefault()
    }

    isString(val) {
        return typeof val;
    }

    navigateTo(e, path) {
        e.preventDefault();
        this.props.history.push(path)
    }

    async updateAppData(url, data) {
        const config = {};
        const response = await axios.post(this.apiUrl + url, data, config);
        return response.data;
    }

}

export default CommonComponent
