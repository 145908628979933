import React, {Component} from 'react'
import SubpageTitle from "../widgets/SubpageTitle";
import HowItWorks from "../widgets/HowItWorks";
import {MSG} from "../../shared/Messages";
import ClientBrands from "../widgets/ClientBrands";
import {HealthyBanner} from "../widgets/HealthyBanner";
import PageNotFound from "./PageNotFound";
import CommonComponent from "../common/CommonComponent";

export class DefaultPage extends CommonComponent {
    constructor(props) {
        super(props);
    }


    render() {
        const {data, location} = this.props;
        const page = data && data.pages && data.pages.filter(a => a.path === location.pathname)[0];
        if(this.isBrowser) {
            this.props.setTitle(page?.meta?.title);
        }
        return (
            <React.Fragment>
                {page ? (<React.Fragment>
                    <SubpageTitle data={this.props.data} location={this.props.location}/>
                    <div className="site-main">
                        <section className="ttm-row about-top-section clearfix">
                            <div className="container">
                                <div className="row">
                                    {page.img ? <div className="col-lg-5 col-md-12 col-sm-12">
                                        <div className="ttm_single_image-wrapper res-991-mt-60 mb-35">
                                            <div className="text-left">
                                                <img className="img-fluid"
                                                     src={`${this.imgUrl}${page?.img}`} alt={page.title}/>
                                            </div>
                                        </div>
                                    </div> : ''}
                                    <div className={ page.img ? "col-lg-7 col-md-12 col-sm-12" : "col-sm-12"}>
                                        <div className="ml_50 res-1199-ml-0">
                                            <div className="section-title clearfix">
                                                <div className="title-header">
                                                    <h5>{page?.subtitle}</h5>
                                                    <h2 className="title"
                                                        dangerouslySetInnerHTML={MSG.markup(page?.title)}/>
                                                </div>
                                                <div className="title-desc"
                                                     dangerouslySetInnerHTML={MSG.markup(page?.content)}/>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </React.Fragment>) : <PageNotFound data={data}/>}

            </React.Fragment>
        );
    }
}

export default DefaultPage;
