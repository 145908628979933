import React, {Component} from 'react'
import {Link} from "react-router-dom";
import Moment from "moment";
import CommonComponent from "../common/CommonComponent";

export class RecentPosts extends CommonComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {posts} = this.props.data;
        return (
            <div className="widget widget_text clearfix">
                <h3 className="widget-title">Recent Posts</h3>
                <ul className="widget-post ttm-recent-post-list">
                    {posts && posts.length &&
                    posts.slice(0, 3).map((item, i) => (
                        <li key={i}>
                            <a href={`/post/${item.path}`}><img
                                src={`${this.imgUrl}${item.img}`} alt="post-img"/></a>
                            <span className="post-date"><i className="fa fa-calendar"/>{Moment(item.updated_at).format('LL')}</span>
                            <a href={`/post/${item.path}`} className="clearfix">{item.title}</a>
                        </li>
                    ))
                    }
                </ul>
            </div>
        );
    }
}

export default RecentPosts;
