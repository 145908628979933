import React, {Component} from 'react'

export class ClientBrands extends Component {
    constructor(props) {
        super(props);
        this.state= {
            data: this.props.data
        }
    }

    render() {
        return (
            <div className="ttm-row home3-client-section text-center clearfix">
                <div className="container">
                    {/* row   */}
                    {this.state.data?.clientBrands?.content.length && (
                        <div className="row res-1199-mlr-15">
                            <div className="col-md-12 box-shadow1 ttm-bgcolor-white mb_90 z-1 res-991-mb_70">
                                <div className="row">
                                    {/* ttm-client   */}
                                    <div className="ttm-client clients-slide owl-carousel owl-theme owl-loaded"
                                         data-item="5" data-nav="false" data-dots="false" data-auto="true">
                                        <div className="owl-stage-outer">
                                            <div className="owl-stage">
                                                {this.state.data?.clientBrands?.content?.map((item, i) => (
                                                    <div key={i} className="owl-item active">
                                                        <div className="client style2">
                                                            <div className="ttm-client-logo">
                                                                <img src={item.imgPath} alt={item.title}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                    </div>
                                    {/* ttm-client end   */}
                                </div>
                            </div>
                        </div>
                    )}
                    {/* row end   */}
                </div>
            </div>
        );
    }
}

export default ClientBrands;

/*{
 "status": 200,
 "result": [
 {
 "_id": "5f42bc094def991be44b54c6",
 "content": [
 {
 "step": "01",
 "imgPath": "images/client/client-03.png",
 "title": "name of badge",
 "desc": ""
 },
 {
 "step": "01",
 "imgPath": "images/client/client-02.png",
 "title": "name of badge",
 "desc": ""
 },
 {
 "step": "01",
 "imgPath": "images/client/client-01.png",
 "title": "name of badge",
 "desc": ""
 },
 {
 "step": "01",
 "imgPath": "images/client/client-04.png",
 "title": "name of badge",
 "desc": ""
 },
 {
 "step": "01",
 "imgPath": "images/client/client-05.png",
 "title": "name of badge",
 "desc": ""
 }
 ],
 "desc": "",
 "img": "",
 "name": "clients",
 "subtitle": "",
 "title": "client logos",
 "type": "obj"
 }
 ]
 }*/
