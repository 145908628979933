import React, {Component} from 'react'

export class Search extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {data} = this.props;
        return (
            <div className="widget widget-search">
                <form role="search" method="get" className="search-form  box-shadow" action="#">
                    <label>
                        <span className="screen-reader-text">Search for:</span>
                        <i className="fa fa-search"/>
                        <input type="search" className="input-text" placeholder="Search …"
                               value=""
                               name="s"/>
                    </label>
                </form>
            </div>
        );
    }
}

export default Search;
