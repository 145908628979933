import React, {Component} from 'react'
import {Link} from "react-router-dom";
import SubpageTitle from "../widgets/SubpageTitle";
import Moment from 'moment';
import PostAuth from "../widgets/PostAuth";
import Search from "../widgets/Search";
import RecentPosts from "../widgets/RecentPosts";
import TagsSidebar from "../widgets/TagsSidebar";
import SidebarBanner from "../widgets/SidebarBanner";
import CommonComponent from "../common/CommonComponent";

export class PostsPage extends CommonComponent {
    constructor(props) {
        super(props);
        const {data, location} = this.props;
        this.state = {data};
        if (this.isBrowser) {
            const stateData = window.WINDOW_STATE;
            if (!stateData) {
                this.init();
            }
        }
    }

    init() {
        this.loadData(`/pages${this.props.location.pathname}`).then((res) => {
            if (res.length) {
                const {data} = this.state;
                this.setPages(data, res[0]);
                this.setState({data});
            }
        });
    }

    render() {
        const {location} = this.props;
        const {data} = this.state;
        const posts = data && data.posts || [];
        const tags = data && data.widgets && data.widgets.filter(a => a.name === 'appTags')[0];
        const authorData = data && data.widgets && data.widgets.filter(a => a.name === 'authorData')[0];
        const banner = data && data.widgets && data.widgets.filter(a => a.name === 'banner')[0];
        if(this.isBrowser) {
            this.props.setTitle('Welsome to dr Radas nutricare blog posts');
        }
        return (
            <React.Fragment>

                <SubpageTitle data={this.props.data} location={this.props.location}/>
                <div className="site-main">
                    <div className="sidebar ttm-sidebar-right ttm-bgcolor-white break-991-colum clearfix">
                        <div className="container">
                            <div className="row">
                                {posts?.length ? (<React.Fragment>
                                    <div className="col-lg-9 content-area">
                                        {posts.map((item, i) => (
                                            <article key={i} className="post ttm-blog-classic">
                                                <div
                                                    className="featured-imagebox featured-imagebox-post ttm-box-view-top-image box-shadow1">
                                                    <div className="ttm-post-featured-outer">
                                                        <div className="featured-thumbnail">
                                                            <img className="img-fluid"
                                                                 src={`${this.imgUrl}${item?.img}`} alt={item.title}/>
                                                        </div>
                                                        <div className="ttm-box-post-date">
                                                            <span
                                                                className="entry-month">{Moment(item.updated_at).format('D')}</span>
                                                            {Moment(item.updated_at).format('MMM')}
                                                        </div>
                                                    </div>
                                                    <div className="featured-content featured-content-post">
                                                        <div className="post-meta above-title pb-13">
                                                <span className="ttm-meta-line byline"><i
                                                    className="fa fa-user ttm-textcolor-skincolor"/> {item?.auth?.name}</span>
                                                            <span className="ttm-meta-line comments-link"><i
                                                                className="fa fa-comment ttm-textcolor-skincolor"/> {item.comments} Comments</span>
                                                            <span className="ttm-meta-line byline"><i
                                                                className="fa fa-eye ttm-textcolor-skincolor"/> {item.views} Views</span>
                                                        </div>
                                                        <div className="separator clearfix">
                                                            <div className="sep-line mt_5 mb-30"/>
                                                        </div>
                                                        <div className="post-title featured-title">
                                                            <h5><a href={`/post/${item.path}`} tabIndex="-1">{item.title}</a>
                                                            </h5>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p className="text-justify">{item.subtitle}</p>
                                                        </div>
                                                        <a href={`/post/${item.path}`}
                                                              className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-btn-underline mb-10">Read
                                                            More</a>
                                                    </div>
                                                </div>
                                            </article>
                                        ))}

                                        {/*<div className="ttm-pagination">
                                            <span className="page-numbers current">1</span>
                                            <a className="page-numbers" href="#">2</a>
                                            <a className="next page-numbers" href="#"><i
                                                className="ti ti-arrow-right"/></a>
                                        </div>*/}
                                    </div>
                                </React.Fragment>) : (<p>No Posts found</p>)}
                                <div
                                    className="col-lg-3 widget-area sidebar-right ttm-col-bgcolor-yes ttm-bg ttm-right-span ttm-bgcolor-grey">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"/>
                                    <aside>
                                        {authorData ? <PostAuth data={authorData}/> : ''}
                                    </aside>
                                    {/*<aside>
                                        <Search/>
                                    </aside>*/}
                                    <aside>
                                        <RecentPosts data={data}/>
                                    </aside>
                                    <aside>
                                        <SidebarBanner data={banner}/>
                                    </aside>
                                    <aside>
                                        <TagsSidebar data={tags}/>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>


        );
    }
}

export default PostsPage;
