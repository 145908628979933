import React, {Component} from 'react'
import {Form, Alert} from 'react-bootstrap'
import {ValidationsHelper} from "../common/ValidationHelper";
import {CommonComponent} from "../common/CommonComponent";

export class ContactForm extends CommonComponent {
    formSubmitted = false;
    constructor(props) {
        super(props);
        this.state = {
            form: {
                name: '',
                email: '',
                phone: '',
                subject: '',
                comment: ''
            },
            errors: {}
        };
        this.onValueChange = this.onValueChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }


    onValueChange(e, field, valType = String) {
        e.preventDefault();
        let form = this.state.form;
        const value = field !== 'enabled' ? valType(e.target.value) : e.target.checked;
        form[field] = value;
        this.setState({form}, () => {
            if (this.formSubmitted) {
                const errors = this.state.errors;
                ValidationsHelper.getErrors(value || "", field, errors, 'app');
                this.setState({errors});
            }

        });
    }

    saveChanges(e) {
        e.preventDefault();
        this.formSubmitted = true;
        if (this.validateForm()) {
            const reqObj = this.prepareReqObj();
            if (reqObj) {
                this.updateAppData(`/messages/add`, reqObj).then(res => {
                    if(res.status === 200) {
                        this.showAlert('PAGE_UPDATE_SUCCESS');
                        const form = this.state.form;
                        form.name = '';
                        form.email = '';
                        form.subject = '';
                        form.phone = '';
                        form.comment = '';
                        this.setState({form});
                    }

                });

            }

        } else {
            this.showAlert('PAGE_VALID_FAILED', 'danger');
        }
    }

    validateForm() {
        const errors = this.state.errors;
        const formIsValid = ValidationsHelper.isListValid(this.state.form, errors, 'app');
        this.setState({errors});
        return formIsValid;
    }

    prepareReqObj() {
        return this.state.form;
    }

    render() {
        return (
            <React.Fragment>
                {this.state.alertData ? <Alert dismissible variant={this.state.alertData.variant}
                                               onClick={this.closeAlert}>{this.state.alertData.text}</Alert> : ''}
            <Form onSubmit={this.saveChanges}  id="ttm-contactform" className="ttm-contactform wrap-form clearfix" >
                    <Form.Group>
                        <Form.Control
                            value={this.state.form.name}
                            placeholder="Name"
                            isInvalid={this.state.errors.name}
                            onChange={e => this.onValueChange(e, 'name')}/>
                        {this.state.errors.name ?
                            <Form.Control.Feedback type="invalid">
                                {this.state.errors.name}
                            </Form.Control.Feedback> : ''}
                    </Form.Group>

                    <Form.Group>
                        <Form.Control
                            value={this.state.form.email}
                            placeholder="Email"
                            isInvalid={this.state.errors.email}
                            onChange={e => this.onValueChange(e, 'email')}/>
                        {this.state.errors.email ?
                            <Form.Control.Feedback type="invalid">
                                {this.state.errors.email}
                            </Form.Control.Feedback> : ''}
                    </Form.Group>
                <Form.Group>
                    <Form.Control
                        value={this.state.form.phone}
                        placeholder="Phone"
                        isInvalid={this.state.errors.phone}
                        onChange={e => this.onValueChange(e, 'phone')}/>
                    {this.state.errors.phone ?
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.phone}
                        </Form.Control.Feedback> : ''}
                </Form.Group>
                    <Form.Group>
                        <Form.Control
                            value={this.state.form.subject}
                            placeholder="Medical Diagnosis"
                            isInvalid={this.state.errors.subject}
                            onChange={e => this.onValueChange(e, 'subject')}/>
                        {this.state.errors.subject ?
                            <Form.Control.Feedback type="invalid">
                                {this.state.errors.subject}
                            </Form.Control.Feedback> : ''}
                    </Form.Group>
                <Form.Group>
                    <Form.Control as="textarea" rows="6" placeholder="Additional information" name="comment" cols="45"
                                  value={this.state.form.comment}
                                  isInvalid={this.state.errors.comment}
                                  onChange={e => this.onValueChange(e, 'comment')}/>
                    {this.state.errors.comment ?
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.comment}
                        </Form.Control.Feedback> : ''}
                </Form.Group>
                <input name="submit" type="submit" id="submit"
                       className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-round  ttm-btn-style-fill ttm-btn-bgcolor-skincolor mb-5"
                       value="Submit"/>
            </Form>
            </React.Fragment>
        );
    }
}

export default ContactForm;
