import React from 'react'
import {ValidationsHelper} from "../common/ValidationHelper";
import {Form, Alert} from 'react-bootstrap'
import {CommonComponent} from "../common/CommonComponent";

export class NewsLetter extends CommonComponent {
    formSubmitted = false;

    constructor(props) {
        super(props);
        this.state = {
            form: {
                email: ""
            },
            errors: {}
        };
        this.onValueChange = this.onValueChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    onValueChange(e, field, valType = String) {
        e.preventDefault();
        let form = this.state.form;
        const value = field !== 'enabled' ? valType(e.target.value) : e.target.checked;
        form[field] = value;
        this.setState({form}, () => {
            if (this.formSubmitted) {
                const errors = this.state.errors;
                ValidationsHelper.getErrors(value || "", field, errors, 'app');
                this.setState({errors});
            }

        });
    }

    saveChanges(e) {
        e.preventDefault();
        this.formSubmitted = true;
        if (this.validateForm()) {
            const reqObj = this.getPostData();
            if (reqObj) {
                this.updateAppData(`/subscribers/add`, reqObj);
                this.showAlert('PAGE_UPDATE_SUCCESS');
                const form = {
                    email: ""
                };
                this.setState({form});
                this.formSubmitted = false;
            }

        } else {
            this.showAlert('PAGE_VALID_FAILED', 'danger');
        }
    }

    validateForm() {
        const errors = this.state.errors;
        const formIsValid = ValidationsHelper.isListValid(this.state.form, errors, 'app');
        this.setState({errors});
        return formIsValid;
    }

    getPostData() {
        return this.state.form;
    }

    render() {
        return (
            <div className="widget clearfix">
                <div className="ttm-newsletter-box">
                    <h3 className="widget-title">Know more:</h3>
                    <p>What we do at clinic is simple: we cut through all the clutter of
                        (mis)information and get to the root of your problem and design a solution that’s right for
                        you. For more info, provide your details</p>
                    {this.isBrowser ? <Form className="mc4wp-form mc4wp-form-24" method="post" data-id="24"
                                            onSubmit={this.saveChanges} data-name="Newsletter Form">
                        <div className="mc4wp-form-fields">
                            <div className="mailchimp-inputbox">
                                <Form.Group>
                                    <Form.Control
                                        type="email"
                                        value={this.state.form.email}
                                        placeholder="Your email address.."
                                        isInvalid={this.state.errors.email}
                                        onChange={e => this.onValueChange(e, 'email')}/>
                                    {this.state.errors.email ?
                                        <Form.Control.Feedback type="invalid">
                                            {this.state.errors.email}
                                        </Form.Control.Feedback> : ''}
                                </Form.Group>


                                <input type="submit" value="Subscribe"/>
                            </div>
                            {this.state.alertData ? <Alert dismissible variant={this.state.alertData.variant}
                                                           onClick={this.closeAlert}>{this.state.alertData.text}</Alert> : ''}
                        </div>
                        <div className="mc4wp-response"/>
                    </Form> : ''}
                </div>
            </div>
        );
    }
}

export default NewsLetter;
