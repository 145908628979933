import React, {Component} from 'react'

export class PostAuth extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {data} = this.props;
        return (
            <div className="widget widget-text">
                <div className="ttm-author-widget">
                    <div className="author-widget_img">
                        <img className="author-img"
                             src={data?.img}
                             alt={data?.title}/>
                    </div>
                    <h4 className="author-name">{data?.title}</h4>
                    <p className="small"><i>{data?.subtitle}</i></p>
                    <p className="author-widget_text">{data?.desc}</p>
                </div>
            </div>
        );
    }
}

export default PostAuth;
