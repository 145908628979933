import React, {Component} from 'react'
import {MSG} from "../../shared/Messages";
import {Link} from "react-router-dom";

export class HomeBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            isleftAlign: this.getAlign(),
            featureInd: 0,
            features: this.getFeatures(this.props.data)
        };
        if(this.state.features.length) {
            setInterval(() => {
                let featureInd = this.state.featureInd;
                featureInd++;
                featureInd = this.state.features.length == featureInd ? 0 : featureInd ;
                this.setState({featureInd});
            }, 3000)
        }
    }

    getAlign(){
             const item = this.props.data.content.filter(a => a.name === 'align')[0];
             return !(item && item.value === 'right');
    }
    getFeature(items){
        return items[this.state.featureInd];
    }

    getFeatures(data){
        if(data.desc) {
            const desc = data.desc.split(':');
            return desc[1] ? desc[1].split(',') : [];
        }
        return [];
    }
    getFeatureSlide(){
        let feature = this.state.features[this.state.featureInd];
        if(feature) {
            const data = feature.split('');
            feature = `<strong class="ttm-textcolor-skincolor h2">${data.splice(0,1)}</strong>${data.join('')}`;
            return (<div className="main-font h3 text-uppercase"
                         dangerouslySetInnerHTML={MSG.markup(feature)}/>)
        }

    }


    getDescription(data) {
        if(data.desc) {
            const desc = data.desc.split(':');
            return (<React.Fragment>
                <span dangerouslySetInnerHTML={MSG.markup(desc[0])} />
            </React.Fragment>)
        }
       return '';
    }

    render() {
        const {data} = this.props;
        return (
            <div id="rev_slider_4_1_wrapper"
                 className="rev_slider_wrapper fullwidthbanner-container slide-overlay tp-mouseover"
                 data-alias="classic4export" data-source="gallery">
                <div id="rev_slider_4_1"
                     className="rev_slider fullwidthabanner revslider-initialised tp-simpleresponsive rev_redraw_on_blurfocus"
                     data-slideactive="rs-1">
                    <ul className="tp-revslider-mainul">

                        <li className="tp-revslider-slidesli active-revslide">

                            <div className="slotholder">
                                <div style={{backgroundImage: `url(${data.img})` }} className="tp-bgimg defaultimg "/>
                            </div>
                            {this.state.isleftAlign ? <React.Fragment>
                                <div className="tp-parallax-wrap slidetxt3">
                                    <div className="tp-loop-wrap">
                                        <div className="tp-mask-wrap">
                                            <div
                                                className="tp-caption tp-shape tp-shapewrapper ttm-bgcolor-skincolor tp-resizeme"
                                                id="slide-8-layer-1"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="tp-parallax-wrap slidetxt4">
                                    <div className="tp-loop-wrap">
                                        <div className="tp-mask-wrap">
                                            <div className="tp-caption tp-resizeme" id="slide-8-layer-2"> {data.subtitle}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tp-parallax-wrap slidetxt5">
                                    <div className="tp-loop-wrap">
                                        <div className="tp-mask-wrap">
                                            <div className="tp-caption main-font tp-resizeme" id="slide-8-layer-3"
                                                 dangerouslySetInnerHTML={MSG.markup(data.title)} />
                                        </div>
                                    </div>
                                </div>


                            <div className="tp-parallax-wrap slidetxt8">
                                <div className="tp-loop-wrap">
                                    <div className="tp-mask-wrap">
                                        <div className="tp-caption tp-resizeme" id="slide-8-layer-6"
                                             dangerouslySetInnerHTML={MSG.markup(data.desc)} />
                                    </div>
                                </div>
                            </div>

                                <div className="tp-parallax-wrap slidetxt2">
                                    <div className="tp-loop-wrap">
                                        <div className="tp-mask-wrap"><a
                                            className="tp-caption button skin-button tp-resizeme" href="contact"
                                            id="slide-8-layer-7">Conatct Us</a></div>
                                    </div>
                                </div>

                                <div className="tp-parallax-wrap slidetxt">
                                    <div className="tp-loop-wrap">
                                        <div className="tp-mask-wrap"><a
                                            className="tp-caption button black-button tp-resizeme " href="posts"
                                            id="slide-8-layer-8">Our Blog </a></div>
                                    </div>
                                </div>
                            </React.Fragment> : <div className="slidetxtRight">
                                <div className="tp-parallax-wrap float-right ">
                                    <div className="tp-loop-wrap">
                                        <div className="tp-mask-wrap">
                                            <span
                                                className="text-right tp-caption tp-shape tp-shapewrapper ttm-bgcolor-skincolor tp-resizeme"
                                                id="slide-8-layer-1"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="tp-parallax-wrap ">
                                    <div className="tp-loop-wrap">
                                        <div className="tp-mask-wrap">
                                            <div className="ttm-textcolor-skincolor subtitle text-right"> {data.subtitle}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"/>
                                <div className="tp-parallax-wrap d-block clearfix">
                                    <div className="tp-loop-wrap">
                                        <div className="tp-mask-wrap">
                                            <div className="tp-caption main-font float-right tp-resizeme title text-right" id="slide-8-layer-3"
                                                 dangerouslySetInnerHTML={MSG.markup(data.title)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="tp-parallax-wrap d-block clearfix">
                                    <div className="tp-loop-wrap">
                                        <div className="tp-mask-wrap">
                                            <div className="tp-caption tp-resizeme description float-right" id="slide-8-layer-6">
                                                {this.getDescription(data)}
                                                {this.getFeatureSlide()}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tp-parallax-wrap viewSchedule">
                                    <div className="tp-loop-wrap">
                                        <div className="tp-mask-wrap"><a
                                            className="tp-caption button black-button tp-resizeme " href="posts"
                                            id="slide-8-layer-8">Our Blog </a></div>
                                    </div>
                                </div>
                                <div className="tp-parallax-wrap contactUs">
                                    <div className="tp-loop-wrap">
                                        <div className="tp-mask-wrap"><a
                                            className="tp-caption button skin-button tp-resizeme" href="contact"
                                            id="slide-8-layer-7">Online Consultation</a></div>
                                    </div>
                                </div>
                            </div>}

                        </li>
                    </ul>

                </div>
            </div>
        );
    }
}

export default HomeBanner;
