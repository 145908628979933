import React, {Component} from 'react'
import {Link} from "react-router-dom";
import CommonComponent from "../common/CommonComponent";

export class SubpageTitle extends CommonComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };

        this.refreshPage = this.refreshPage.bind(this)
    }

    refreshPage(e, path) {
        if(this.props.refreshPage) {
            this.props.refreshPage(e, path);
        }

    }

    render() {
        const {data, location} = this.props;
        let parentPage;
        let currentPage = data && data.menu && data.menu.filter(a => a.path === location.pathname)[0];
         if(!currentPage) {
             data.menu && data.menu.map(a => {
                 const x = a.children && a.children.filter(b => b.path === location.pathname)[0];
                 if(x) {
                     parentPage = a;
                     currentPage =  x
                 }

             });
         }
        if(!currentPage && data.post) {
            currentPage = data.post;
            currentPage.desc = data.post.title;
        }
        const homePage = data && data.menu && data.menu.filter(a => a.path === '/' || a.path === '')[0];
        return (
            <div className="ttm-page-title-row"  style={{backgroundImage: `url('${this.imgUrl}${this.state.data.images?.pagesBGHeadImage}')`}}>
                <div className="ttm-page-title-row-bg-layer ttm-bg-layer"/>
                <div className="container">
                    {currentPage && homePage && (
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="title-box ttm-textcolor-white">
                                    <div className="page-title-heading ttm-textcolor-white">
                                        <h1 className="title">{currentPage.desc}</h1>
                                    </div>
                                    <div className="breadcrumb-wrapper">
                                <span>
                                    <a title={homePage.desc} href={homePage.path}><i
                                        className="ti ti-home"/> {homePage.desc}</a>
                                </span>
                                        {/*{parentPage ? <React.Fragment>
                                            <span className="ttm-bread-sep"> &nbsp; :&nbsp;: &nbsp; </span>
                                            <Link title={parentPage.desc} to={parentPage.path} onClick={(e) => this.refreshPage(e, parentPage.path)}>{parentPage.desc}</Link>
                                        </React.Fragment> : ''}*/}
                                        <span className="ttm-bread-sep"> &nbsp; :&nbsp;: &nbsp; </span>
                                        <span><span className="ttm-textcolor-skincolor">{currentPage.desc}</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default SubpageTitle;
