import React, {Component} from 'react'
import Moment from "moment";
import CommonComponent from "../common/CommonComponent";

export class CommentsList extends CommonComponent {
    constructor(props) {
        super(props);
        this.state = {
            comments: this.props.data || [],
        };
    }

    render() {
        return (
            <div className="comments-body">

                {this.state.comments?.length && this.state.comments.map((item, i) => (
                    <div key={i} className="media p-3">
                        <img src={item.user?.icon}
                             alt={item.user?.name} className=""/>
                        <div className="media-body">
                            <h4>{item.user?.name}</h4>
                            <small>
                                <i>{Moment(item.created_at).format('LLLL')}</i>
                            </small>
                            <p className="pt-10">{item.comment}</p>
                            {/*<ul className="list-inline">
                                <li
                                className="float-right text-lightest small mr-2"><a
                                className="fa fa-thumbs-down text-info nav-link"/></li>
                                <li
                                    className="float-right text-lightest small mr-2" ><a
                                    className="fa fa-comment text-info nav-link" /></li>
                                <li className="float-right text-lightest small mr-2" ><a
                                    className="fa fa-thumbs-up  text-info nav-link"/></li>
                            </ul>*/}
                        </div>
                    </div>
                ))}


            </div>
        );
    }
}

export default CommentsList;
