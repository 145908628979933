import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {MSG} from "../../shared/Messages";
import {CommonComponent} from "../common/CommonComponent";

export class HealthyBanner extends CommonComponent {
    constructor(props) {
        super(props);
        this.state= {
            data: this.props.data
        }
    }

    render() {
        return (
            <section className="ttm-row home3-row-title-section bg-img7 ttm-bg clearfix"  style={{backgroundImage: `url('${this.imgUrl}${this.state.data?.images?.HealthyBanner}')`}} >
                <div className="container">{/*container  */}
                    <div className="row">{/*row  */}
                        <div className="col-lg-12 text-center">
                            {/* section title   */}
                            <div className="row-title style5 clearfix">
                                <p>{this.state.data?.healthyBanner?.subtitle}</p>
                                <div className="title-header" dangerouslySetInnerHTML={MSG.markup(this.state.data?.healthyBanner?.title)}/>
                            </div>
                            {/* section title end   */}
                            <div className="mt-30 res-991-mt-30">
                                <a href="/contact"
                                      className="ttm-btn ttm-btn-size-md ttm-btn-shape-round  ttm-btn-style-fill ttm-btn-bgcolor-skincolor mb-20"
                                >Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HealthyBanner;
