import React from 'react'
import {Form, Alert} from 'react-bootstrap'
import {ValidationsHelper} from "../common/ValidationHelper";
import {CommonComponent} from "../common/CommonComponent";

export class AddComment extends CommonComponent {
    formSubmitted = false;

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            form: {
                name: '',
                email: '',
                comment: ''
            },
            errors: {}
        };
        this.onValueChange = this.onValueChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    onValueChange(e, field, valType = String) {
        e.preventDefault();
        let form = this.state.form;
        const value = field !== 'enabled' ? valType(e.target.value) : e.target.checked;
        form[field] = value;
        this.setState({form}, () => {
            if (this.formSubmitted) {
                const errors = this.state.errors;
                ValidationsHelper.getErrors(value || "", field, errors, 'app');
                this.setState({errors});
            }

        });
    }

    saveChanges(e) {
        e.preventDefault();
        this.formSubmitted = true;
        if (this.validateForm()) {
            const reqObj = this.getPostData();
            if (reqObj) {
                this.updateAppData(`/comments/add`, reqObj).then(res => {
                    if(res.status === 200) {
                        this.showAlert('PAGE_UPDATE_SUCCESS');
                        const form = this.state.form;
                        form.name = '';
                        form.email = '';
                        form.comment = '';
                        this.setState({form});
                    }

                });

            }

        } else {
            this.showAlert('PAGE_VALID_FAILED', 'danger');
        }
    }

    validateForm() {
        const errors = this.state.errors;
        const formIsValid = ValidationsHelper.isListValid(this.state.form, errors, 'app');
        this.setState({errors});
        return formIsValid;
    }

    getPostData() {
        const reqobj = this.state.form;
        reqobj.postid = this.props.data.postid;
        if (this.props.data.parent) {
            reqobj.parent = this.props.data.parent;
        }
        return reqobj;
    }

    render() {
        return (<React.Fragment>
                {this.state.alertData ? <Alert dismissible variant={this.state.alertData.variant}
                                               onClick={this.closeAlert}>{this.state.alertData.text}</Alert> : ''}

                <form onSubmit={this.saveChanges} id="commentform"
                      className="comment-form">
                    <p className="comment-notes"> Your email address will not be published. </p>
                    <Form.Group>
                        <Form.Control as="textarea" rows="6" placeholder="Comment" name="comment" cols="45"
                                      value={this.state.form.comment}
                                      isInvalid={this.state.errors.comment}
                                      onChange={e => this.onValueChange(e, 'comment')}/>
                        {this.state.errors.comment ?
                            <Form.Control.Feedback type="invalid">
                                {this.state.errors.comment}
                            </Form.Control.Feedback> : ''}
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            value={this.state.form.name}
                            placeholder="Name"
                            isInvalid={this.state.errors.name}
                            onChange={e => this.onValueChange(e, 'name')}/>
                        {this.state.errors.name ?
                            <Form.Control.Feedback type="invalid">
                                {this.state.errors.name}
                            </Form.Control.Feedback> : ''}
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            value={this.state.form.email}
                            placeholder="email"
                            isInvalid={this.state.errors.email}
                            onChange={e => this.onValueChange(e, 'email')}/>
                        {this.state.errors.email ?
                            <Form.Control.Feedback type="invalid">
                                {this.state.errors.email}
                            </Form.Control.Feedback> : ''}
                    </Form.Group>
                    {/*<p className="comment-form-cookies-consent">
                    <input id="comment-cookies-consent"
                           name="comment-cookies-consent" type="checkbox"
                           value="yes"/>
                    <label htmlFor="comment-cookies-consent">Save my name,
                        email, and website in this browser for the next time
                        I comment.</label>
                </p>*/}
                    <p className="form-submit">
                        <input name="submit" type="submit" id="submit"
                               className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-round ttm-btn-style-fill ttm-btn-bgcolor-skincolor"
                               value="Post Comment"/>
                    </p>
                </form>
            </React.Fragment>

        );
    }
}

export default AddComment;
