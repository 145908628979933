import React from 'react'
import PostAuth from "../widgets/PostAuth";
import RecentPosts from "../widgets/RecentPosts";
import SidebarBanner from "../widgets/SidebarBanner";
import TagsSidebar from "../widgets/TagsSidebar";
import SubpageTitle from "../widgets/SubpageTitle";
import Moment from 'moment';
import {MSG} from "../../shared/Messages";
import AddComment from "../widgets/AddComment";
import CommonComponent from "../common/CommonComponent";
import PageNotFound from "./PageNotFound";
import CommentsList from "../widgets/CommentsList";

export class SinglePost extends CommonComponent {
    constructor(props) {
        super(props);
        this.state = {
            post: {}
        };
        if (this.isBrowser) {
            this.loadData(`/posts/path/${this.props.match.params.id}`).then(data => {
                const post = data[0];
                post.auth = {
                    name: 'admin'
                };
                this.setState({post});
                this.loadData(`/posts/views/${post._id}`).then(comments => {
                });
                this.loadData(`/comments/${post._id}`).then(comments => {
                    comments.map(a => {
                        a.user = {
                            name: 'admin',
                            icon: 'https://via.placeholder.com/100/525859.jpg'
                        }
                    });
                    post.commentsData = comments;
                    this.setState({post});
                })
            }, e => {
                console.log(e.message)
            });
        }
        this.submitComment = this.submitComment.bind(this);
    }

    submitComment(e) {
        console.log(e)
    }

    render() {
        const {data, location} = this.props;
        let post;
        if (this.isBrowser) {
            post = this.state.post || {};
        } else {
            post = this.props.data.posts.filter(a => a.path === this.props.match.params.id)[0];
        }
        const tags = data && data.widgets && data.widgets.filter(a => a.name === 'appTags')[0];
        const authorData = data && data.widgets && data.widgets.filter(a => a.name === 'authorData')[0];
        const banner = data && data.widgets && data.widgets.filter(a => a.name === 'banner')[0];
        if (this.isBrowser) {
            this.props.setTitle(post?.meta?.title);
        }
        return (
            <React.Fragment>
                {post ? (<div>
                    <SubpageTitle data={{...this.props.data, post}} location={this.props.location}/>
                    <div className="site-main">
                        <div className="sidebar ttm-sidebar-right ttm-bgcolor-white break-991-colum clearfix">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-9 content-area pb-0">
                                        <article className="post ttm-blog-classic box-shadow1">
                                            <h1>{post.title}</h1>
                                            <div
                                                className="featured-imagebox featured-imagebox-post ttm-box-view-top-image">
                                                <div className="ttm-post-featured-outer mb-20">
                                                    <div className="featured-thumbnail">
                                                        <img className="img-fluid"
                                                             src={`${this.imgUrl}${post?.img}`} alt={post.title}/>
                                                    </div>
                                                    <div className="ttm-box-post-date">
                                                        <span
                                                            className="entry-month">{Moment(post.updated_at).format('D')}</span>
                                                        {Moment(post.updated_at).format('MMM')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ttm-blog-classic-content single-blog ttm-bgcolor-white">
                                                <div className="post-meta above-title pb-13">
                                            <span className="ttm-meta-line byline"><i
                                                className="fa fa-user ttm-textcolor-skincolor"/> {post?.auth?.name}</span>
                                                    <span className="ttm-meta-line comments-link"><i
                                                        className="fa fa-comment ttm-textcolor-skincolor"/> {post.comments} Comments</span>
                                                    <span className="ttm-meta-line byline"><i
                                                        className="fa fa-eye ttm-textcolor-skincolor"/> {post.views} Views</span>
                                                </div>
                                                <div className="separator clearfix">
                                                    <div className="sep-line mt_5 mb-30"/>
                                                </div>
                                                <div className="text-justify"
                                                     dangerouslySetInnerHTML={MSG.markup(post?.content)}/>
                                                {/*<div className="ttm-post-prev-next-buttons clearfix">
                                                    <a href={post.prevPost || "/"}
                                                          className="ttm-btn ttm-btn-size-md ttm-btn-shape-round ttm-icon-btn-left ttm-btn-style-fill ttm-btn-bgcolor-skincolor mb-30"
                                                    ><i className="ti ti-angle-double-left"/>Previous</a>
                                                    <a href={post.nextPost || "/"}
                                                          className="ttm-btn ttm-btn-size-md ttm-btn-shape-round ttm-icon-btn-right ttm-btn-style-fill ttm-btn-bgcolor-skincolor float-right mb-30 ml-15"
                                                    ><i className="ti ti-angle-double-right"/>Next</a>
                                                </div>*/}
                                                <div className="ttm-blog-classic-box-comment">
                                                    <div id="comments" className="comments-area">
                                                        <h2 className="comments-title">{post.comments} Replies
                                                            to “{post.title}”</h2>
                                                        {post.commentsData?.length ?
                                                            <CommentsList parent={{postid: post._id}}
                                                                          data={post.commentsData}/> : ''}
                                                        <div className="comment-respond">
                                                            <h2 className="comment-reply-title">Leave a Reply</h2>
                                                            {this.isBrowser ?
                                                                <AddComment data={{postid: post._id, parent: ''}}
                                                                            submitComment={(e) => this.submitComment(e)}/> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    <div
                                        className="col-lg-3 widget-area sidebar-right ttm-col-bgcolor-yes ttm-bg ttm-right-span ttm-bgcolor-grey">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"/>
                                        <aside>
                                            {authorData ? <PostAuth data={authorData}/> : ''}
                                        </aside>
                                        {/*<aside>
                                            <Search/>
                                        </aside>*/}
                                        <aside>
                                            <RecentPosts data={data}/>
                                        </aside>
                                        <aside>
                                            <SidebarBanner data={banner}/>
                                        </aside>
                                        <aside>
                                            <TagsSidebar data={tags}/>
                                        </aside>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>) : <PageNotFound data={data}/>}
            </React.Fragment>

        );
    }
}

export default SinglePost;
