import React, {Component} from 'react'
import {MSG} from "../../shared/Messages";
import {CommonComponent} from "../common/CommonComponent";

export class HowItWorks extends CommonComponent {
    constructor(props) {
        super(props);
        this.state= {
            data: this.props.data
        }
    }

    render() {
        return (
            <section className="ttm-row processbox-section break-991-colum clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title text-center clearfix">
                                <div className="title-header">
                                    <h5>{this.state.data.howItWorks?.subtitle}</h5>
                                    <h2 className="title">{this.state.data.howItWorks?.title}</h2>
                                </div>
                                <div className="title-desc" dangerouslySetInnerHTML={MSG.markup(this.state.data.howItWorks?.desc)}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            {this.state.data.howItWorks?.content.length && (
                                <div className="ttm-processbox-wrapper">
                                    {this.state.data.howItWorks?.content?.map((item, i) => (
                                        <div key={i} className="ttm-processbox">
                                            <div className="ttm-box-image">
                                                <div className="ttm-process-image">
                                                    <img className="img-fluid"
                                                         src={`${this.imgUrl}${item?.imgPath}`} alt={item.title}
                                                         title={item.title}/>
                                                    <div className="process-num">
                                                        <span className="number">{item.step}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h5 className="ttm-textcolor-skincolor">{item.title}</h5></div>
                                                <div className="ttm-box-description text-left">{item.desc}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HowItWorks;
