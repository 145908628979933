import React, {Component} from 'react'

export class FoundedIn extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {data} = this.props;
        return (
            <div
                className="ttm-fid inside ttm-fid-with-icon ttm-fid-view-roundbox ttm-fid-no-border ttm-column-rightbox">
                <div className="ttm-center-fid text-center">
                    <div className="ttm-fid-icon-wrapper ttm-textcolor-white">
                        <i className={data?.img}/>
                    </div>
                    <div className="ttm-fid-contents">
                        <h3 className="ttm-fid-title">{data?.subtitle}</h3>
                    </div>
                    <h4 className="ttm-fid-inner">
                        <span>{data?.title}</span>
                    </h4>
                </div>
            </div>
        );
    }
}

export default FoundedIn;
