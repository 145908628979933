import React, {Component} from 'react'
import {Link} from "react-router-dom";
import CommonComponent from "../common/CommonComponent";
import {MSG} from "../../shared/Messages";

export class PageNotFound extends CommonComponent {
    render() {
        const {data, location} = this.props;
        const page = data && data.pages && data.pages.filter(a => a.path === '**')[0];
        const images = data && data.images;
        if(this.isBrowser) {
            document.title = `Page not found - nutricare`;
        }
        return (
            <div>
                <section className="error-404 bg-img2"  style={{backgroundImage: `url('${this.imgUrl}${ images?.pageNotFound}')`}} >
                    <div className="ttm-big-icon ttm-textcolor-skincolor">
                        <i className={page?.img}/>
                    </div>
                    <header className="page-header"><h1 className="page-title">{page?.title}</h1></header>
                    <div className="page-content"><p dangerouslySetInnerHTML={MSG.markup(page?.content)}/></div>
                     <a
                        className="ttm-btn ttm-btn-size-md ttm-btn-shape-round ttm-btn-style-border ttm-btn-color-black mb-15"
                        href="/">Back To Home</a>
                </section>
            </div>
        );
    }
}

export default PageNotFound;
