import React from 'react'

import HomePage from './components/pages/Home';
// import Dashboard from './components/pages/Dashboard';
import AboutUs from './components/pages/AboutUs';
import ContactUs from './components/pages/ContactUs';
// import ServicesPage from './components/pages/Services';
import PlansPage from './components/pages/Plans';
import PostsPage from './components/pages/Posts';
import SinglePost from './components/pages/SinglePost';
// import ScheduleAppointment from './components/pages/ScheduleAppointment';
import PageNotFound from './components/pages/PageNotFound';
/*import AuthenticationLogin from "./components/pages/AuthenticationLogin";
import AuthenticationRegister from "./components/pages/AuthenticationRegister";
import AuthenticationPasswordReset from "./components/pages/AuthenticationPasswordReset";*/
import {DefaultPage} from "./components/pages/DefaultPage";
import PlansProfile from "./components/pages/PlansProfile";

export const routes = [
    {
        path: '/',
        component: HomePage
    }, {
        path: '/about',
        component: AboutUs
    },/*{
        path: '/login',
        component: AuthenticationLogin
    },{
        path: '/register',
        component: AuthenticationRegister
    },{
        path: '/forgot-password',
        component: AuthenticationPasswordReset
    }, */{
        path: '/contact',
        component: ContactUs
    }, /*{
        path: '/user',
        component: Dashboard
    },{
        path: '/user/!*',
        component: Dashboard
    }, {
        path: '/services',
        component: ServicesPage
    },  {
        path: '/plans',
        component: PlansPage
    }, */{
        path: '/posts',
        component: PostsPage
    }, {
        path: '/post/:id',
        component: SinglePost
    }, {
        path: '/plans',
        component: PlansPage
    },{
        path: '/plans/:id',
        component: PlansProfile
    }, /*{
        path: '/schedule-appointment',
        component: ScheduleAppointment
    },*/ {
        path: '/*',
        component: DefaultPage
    }, {
        path: '**',
        component: PageNotFound
    }
];
